import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from "react-router-dom";
import Lottie from "react-lottie-player";
import animacionLoading from "../../assets/json/download-lottie.json";
import btnCerrar from "../../assets/png/cerrar/x_normal.png";
import membrete from "../../assets/png/membrete.png";
import "./DescargaPersonaje.scss";
import {Button, Image} from "react-bootstrap";
import {muestraPersonaje} from "../../api/personajes";

function DescargaPersonaje(props) {

    const params = useParams();
    //console.log(params)

    const linkPersonaje = "https://modulo2.micavirtual.com/ni%c3%b1a/ni%c3%b1as%20sin%20cubreboca/original/ni%c3%b1a%20original%20.png";

    useEffect(() => {
        try {
            muestraPersonaje(params.nombre).then(response => {
                const { data } = response;
                //console.log(data);

                // Asignacion de imagen de slider 1
                if(response) {
                    if(response.data.type !== "text/plain" && response.data) {
                        //setImagenSlider1(data);
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        //console.log(archivo)
                        link.setAttribute('download', "personaje.png");
                        document.body.appendChild(link);
                        link.click();

                        window.open("", "_self").window.close()
                    }
                }

            }).catch((e) => {
                if(e.message == 'Network Error') {
                    //console.log("No hay internet")
                }
            })
        } catch (e) {
        }
    }, []);

    const cerrarPagina = () => {
        window.open("", "_self").window.close()
    }

    return (
        <>
            <div className="descargaArchivo">
                <Image src={membrete} className="membrete" />

                <Image src={btnCerrar} className="btnCerrar" onClick={() => {
                    cerrarPagina()
                }} />

                <div className="AnimacionLoading">
                    <Lottie
                        animationData={animacionLoading}
                        play
                    />
                </div>
            </div>
        </>
    );
}

export default withRouter(DescargaPersonaje);

import React from 'react';
import './App.scss';
import Routing from "./routers/Routing";

function App() {
  return (
    <>
      <Routing />
    </>
  );
}

export default App;

/* eslint-disable import/no-anonymous-default-export */
import DescargaPersonaje from "../pages/DescargaPersonaje";

export default [
    {
        path: "/",
        exact: true,
        page: DescargaPersonaje
    },
    {
        path: "/:nombre",
        exact: true,
        page: DescargaPersonaje
    },
]

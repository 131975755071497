import { API_HOST } from "../utils/constants";
import {
    ENDPOINTObtenerPersonaje
} from "./endpoints";
import axios from "axios";



// Obten sliders
export async function muestraPersonaje(nombreImagen){
    //console.log(nombreImagen)
    const config = {
        responseType: 'blob',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerPersonaje + `/${nombreImagen}`, config);
}
